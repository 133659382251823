import React from "react";
import Layout from "../Components/layout";
import ResetPasswordc from "../Components/resetPasswordc";
import { Seo } from "../Components/seo";


export default function ResetPassword() {
  return (
    <Layout>
      <ResetPasswordc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)