import React, { useEffect } from "react";
import useCurrentWidth from "./useCurrentWidth";

export default function ResetPasswordc() {
  const width = useCurrentWidth();
  return (
    <>
    <div style={{color: `black`, minHeight: `100vh`, display: `flex`}}>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}} onClick={() => {window.location.replace("/")}}>
        <img style={{padding: (width < 700) ? `0px 6vw` : `7vw`, width: `48vw`, height: (width < 700) ? `40vw` : `50vw`}} 
      src={(width < 700) ? "/Images/ReviewHeart.png" : "/Images/LoginLeft.png"}></img></div>
      <div style={{width: `5px`, minHeight: `100vh`, backgroundColor: `#e37100`}}/>
      <div style={{width: `49vw`}}><div style={{display: `flex`, justifyContent: `center`, alignItems: `center`}}>
        <img src="/Images/BottomLogo.png" style={{width: (width < 700) ? `41vw` : `20vw`, height: (width < 700) ? `28vw` : `14vw`, marginTop: `20vh`}}></img></div>
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, height: (width < 375) ? `65vh` : `50vh`}}>
        <div style={{color: `black`, fontSize: (width < 700) ? `12px` : `16px`, fontWeight: `800`}}>
        <p style={{width: `45vw`, maxWidth: `550px`}}>To reset your password please enter the email associated with your account and click submit. An email with instructions on how to finish resetting your password will be sent.</p>
        <div>Email</div>
        <div className="login" style={{width: (width < 700) ? `41vw` : `28vw`}}>
        <input  type="text" id="email" name="email"/> 
        <div style={{float: `right`, fontSize: `13px`, cursor: `pointer`, marginTop: `10px`}}>Forgot Password</div>
        <button style={{ cursor: `pointer`, marginTop: `50px`, textTransform: `uppercase`}}>Submit</button>
        <div style={{marginTop:`10px`, fontSize: `13px`, float: `right`, cursor: `pointer`, }}
        onClick={() => {window.location.href="/register"}}>Register</div>
        </div>
        </div>
        </div>
      </div>
    </div>
    </>
  )
}
